<template>
    <div id="content">
        <div class="content">
            <div class="title">
                <h2>{{ isEn ? lists.hdzx.enName : lists.hdzx.cnName }}</h2>
                <router-link :to="lists.hdzx.templateUrl" class="more">{{ $t('lang.more') }}</router-link>
            </div>
            <div style="visibility: visible" :class="['information', 'animate__animated', 'animate__fadeInUp']">
                <div class="activity" v-if="lists.hdzx && lists.hdzx.data && lists.hdzx.data.length > 0">
                    <div class="list">
                        <div class="cover">
                            <div class="link" v-for="(item, index) in lists.hdzx.data" @click="toDetail(item)">
                                <div class="img" :key="index">
                                    <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                                    <span>{{ isEn ? item.enTitle : item.cnTitle }}</span>
                                </div>
                            </div>
                        </div>
<!--                        <ul>-->
<!--                            <li v-for="(item, index) in lists.hdzx.data" :key="index" v-show="index !== 0" v-if="index > 2">-->
<!--                                <span @click="toDetail(item)">{{ isEn ? item.enTitle : item.cnTitle }}</span>-->
<!--                                <router-link :to="'/newsDetail/' + item.id + '?navId=' + item.navigationId">{{ isEn ? item.enTitle : item.cnTitle }}</router-link>-->
<!--                            </li>-->
<!--                        </ul>-->
                    </div>
                </div>
                <!--<div class="news" v-if="lists.gsxw">
                   &lt;!&ndash; <div class="title">
                        <h2>{{ isEn ? lists.gsxw.enName : lists.gsxw.cnName }}</h2>
                        <router-link :to="lists.gsxw.templateUrl" class="more">{{ $t('lang.more') }}</router-link>
                    </div>&ndash;&gt;
                    <div class="list" v-if="lists.gsxw.data.length > 0">
                        <div class="cover">
                            <div class="link" @click="toDetail(lists.gsxw.data[0])">
                                <div class="img">
                                    <img :src="staticPath + (isEn ? lists.gsxw.data[0].enLogo : lists.gsxw.data[0].cnLogo)" alt="">
                                    <span>{{ isEn ? lists.gsxw.data[0].enTitle : lists.gsxw.data[0].cnTitle }}</span>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li v-for="(item, index) in lists.gsxw.data" :key="index" v-show="index !== 0">
                                <span @click="toDetail(item)">{{ isEn ? item.enTitle : item.cnTitle }}</span>
&lt;!&ndash;                                <router-link :to="'/newsDetail/' + item.id">{{ isEn ? item.enTitle : item.cnTitle }}</router-link>&ndash;&gt;
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="publicity" v-if="lists.xxgs">
                    &lt;!&ndash;<div class="title">
                        <h2>{{ isEn ? lists.xxgs.enName : lists.xxgs.cnName }}</h2>
                        <router-link :to="lists.xxgs.templateUrl" class="more">{{ $t('lang.more') }}</router-link>
                    </div>&ndash;&gt;
                    <div class="list" v-if="lists.xxgs.data.length > 0">
                        <div class="cover">
                            <div class="link" @click="toDetail(lists.xxgs.data[0])">
                                <div class="img">
                                    <img :src="staticPath + (isEn ? lists.xxgs.data[0].enLogo : lists.xxgs.data[0].cnLogo)" alt="">
                                    <span>{{ isEn ? lists.xxgs.data[0].enTitle : lists.xxgs.data[0].cnTitle }}</span>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li v-for="(item, index) in lists.xxgs.data" :key="index" v-show="index !== 0">
                                <span @click="toDetail(item)">{{ isEn ? item.enTitle : item.cnTitle }}</span>
&lt;!&ndash;                                <router-link :to="'/newsDetail/' + item.id">{{ isEn ? item.enTitle : item.cnTitle }}</router-link>&ndash;&gt;
                            </li>
                        </ul>
                    </div>
                </div>-->
            </div>
            <div :style="{'visibility': aniObj.picVisible ? 'visible' : 'hidden'}" :class="['pic-area', 'animate__animated', aniObj.pic ? 'animate__fadeInUp' : '']" ref="pic">
                <div class="title" v-if="lists.tpzq">
                    <h2>{{ isEn ? lists.tpzq.enName : lists.tpzq.cnName }}</h2>
                    <router-link :to="lists.tpzq.templateUrl" class="more">{{ $t('lang.more') }}</router-link>
                </div>
                <div class="area" v-if="lists.tpzq.data > 0">
                    <div v-if = "screenWidth <= 750 ? index < 2 : index >= 0" v-for="(item, index) in lists.tpzq.data" :key="index">
                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" @click="toDetail(item)" alt="">
                    </div>
                </div>
            </div>
            <div :style="{'visibility': aniObj.videoVisible ? 'visible' : 'hidden'}" :class="['video-area', 'animate__animated', aniObj.video ? 'animate__fadeInUp' : '']" ref="video">
                <div class="title" v-if="lists.spzq">
                    <h2>{{ isEn ? lists.spzq.enName : lists.spzq.cnName }}</h2>
                    <router-link :to="lists.spzq.templateUrl" class="more">{{ $t('lang.more') }}</router-link>
                </div>
                <ul v-if="lists.spzq">
                    <li v-for="(item, index) in lists.spzq.data" :key="index" @click="toDetail(item)">
                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                        <p>{{ isEn ? item.enTitle : item.cnTitle }}</p>
                        <i></i>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    export default {
        data() {
            return {
                staticPath,
                banners: [],
                lists: {},
                videoIdx: 0,
                contId: '',
                isEn: false,
                picScrollTop: '',
                videoScrollTop: '',
                aniObj: {
                    picVisible: false,
                    pic: false,
                    videoVisible: false,
                    video: false
                },
                screenWidth:document.body.clientWidth
            };
        },
        watch: {
            "$i18n.local": function(val) {
                this.isEn = val === 'en' ? true : false
            }
        },
        methods: {
            getTemplate() {
                return this.$http.get(urls.getNewsTemp + this.contId).then(res => {
                    if (res.data.status === 200) {
                        this.lists = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const pid = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'NewsDetail',
                        params: {
                            id: pid
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },

            // 监听页面滚动
            getScrollTop() {
                var scroll_top = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                    scroll_top = document.body.scrollTop;
                }
                return scroll_top;
            },
            listenPageScrollFun() {
                document.addEventListener('scroll', this.zoomControl, true)
            },
            zoomControl() {
                var diffValue = -(document.body.clientHeight * 0.5);
                this.winScrollHeight = this.getScrollTop();
                //if (this.picScrollTop < this.winScrollHeight + 700) {
                if (!this.aniObj.pic && this.winScrollHeight >= this.$refs.pic.offsetTop + diffValue) {
                    this.aniObj.picVisible = true;
                    this.aniObj.pic = true;
                }
                //if (this.videoScrollTop < this.winScrollHeight + 600) {
                if (!this.aniObj.video && this.winScrollHeight >= this.$refs.video.offsetTop + diffValue) {
                    this.aniObj.videoVisible = true;
                    this.aniObj.video = true;
                }
            }
        },
        beforeDestroy() {
            document.removeEventListener('scroll', this.zoomControl, true)
        },
        mounted() {
            this.contId = window.atob(decodeURIComponent(this.$route.query.p))

            this.listenPageScrollFun()
            this.getTemplate().then(() => {
                this.picScrollTop = this.$refs.pic.offsetTop;
                this.videoScrollTop = this.$refs.video.offsetTop;
            })
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #content {
        width: 1200px;
        margin: 0 auto;
        box-sizing: border-box;
        .title {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
            height: 46px;
            line-height: 46px;
            /*margin-bottom: 34px;*/
            h2 {
                color: #34254C;
                font-size: 18px;
                height: 40px;
                line-height: 40px;
                +a {
                    color: #A9A6AF;
                    font-size: 14px;
                    position: relative;
                    &::before {
                        content: '';
                        display: inline-block;
                        border: 1px solid #B20000;
                        border-top: 0;
                        border-right: 0;
                        transform: rotate(-135deg);
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        right: 7px;
                        top: 18px;
                    }
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        border: 1px solid #A9A6AF;
                        position: relative;
                        top: 3px;
                        margin-left: 5px;
                    }
                }
                &::after {
                    content: '';
                    display: block;
                    width: 2em;
                    height: 3px;
                    background: #E30D21;
                    border-radius: 1px;
                    margin-top: 4px;
                }
            }
        }
        .information {
            padding: 10px 0;
            >div {
                .list {
                    .cover {
                        line-height: normal;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .link {
                            color: #fff;
                            position: relative;
                            cursor: pointer;
                            margin: 0 30px 30px 0;
                            &:nth-child(3n) {
                                margin-right: 0;
                            }
                            .img {
                                width: 380px;
                                height: 200px;
                                img {
                                    height: 100%;
                                }
                            }
                            span {
                                font-size: 16px;
                                font-weight: normal;
                                display: inline-block;
                                padding:9px 12px;
                                background: rgba(0,0,0,.6);
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                box-sizing: border-box;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                word-break: break-all;
                            }
                        }
                    }
                    ul {
                        height: 12.5vw;
                        overflow: hidden;
                        margin-top: 16px;
                        li {
                            width: 380px;
                            float: left;
                            margin-right: 30px;
                            cursor: pointer;
                            line-height: 2.5vw;
                            word-break: break-all;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 16px;
                            &:nth-child(3n) {
                                margin-right: 0;
                            }
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .pic-area {
            margin-bottom: 60px;
            .area {
                height: auto;
                /*height: 31.5vw;*/
                -moz-column-count: 3;
                -webkit-column-count: 3;
                column-count: 3;
                -moz-column-gap: 1em;
                -webkit-column-gap: 1em;
                column-gap: 1em;
                margin-top: 10px;
                >div {
                    height: 15.25vw;
                    overflow: hidden;
                    border-radius: 10px;
                    img {
                        -moz-page-break-inside: avoid;
                        -webkit-column-break-inside: avoid;
                        break-inside: avoid;
                        vertical-align: middle;
                        cursor: pointer;
                        height: 100%;
                    }
                    &:nth-child(3) {
                        height: 31.5vw;
                        img {
                            width: 200%;
                        }
                    }
                    &:nth-child(1),
                    &:nth-child(4){
                        margin-bottom: 1vw;
                    }
                }
            }
        }
        .video-area {
            margin: 0 0 2vw;
            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 10px;
                li {
                    border-radius: 5px 5px 0 0;
                    overflow: hidden;
                    width: 23%;
                    text-align: center;
                    cursor: pointer;
                    margin-right: 2.66%;
                    margin-bottom: 4%;
                    position: relative;
                    img {
                        height: 156px;
                    }
                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                    p {
                        font-size: 16px;
                        color: #34254C;
                        text-align: left;
                        padding: 12px 16px;
                    }
                    i{
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        border:2px solid #fff;
                        border-radius: 50%;
                        top:130px;
                        left: 10px;
                        &::after{
                            position:absolute;
                            content: '';
                            width: 0;
                            height: 0;
                            border:5px solid transparent;
                            border-left-color: #fff;
                            border-left-width: 7px;
                            top:4px;
                            left: 6px;
                        }
                    }
                }
            }
        }
    }
    @{deep} .video {
        width: 60vw;
        .topic {
            line-height: 3em;
            text-align: center;
            font-size: 1vw;
            font-weight: bold;
            background: rgba(220, 60, 70,.95);
            position: relative;
            .icon-close {
                position: absolute;
                right: 1vw;
                cursor: pointer;
                font-size: 2.4vw;
                font-weight: normal;
                /*&::after {*/
                /*   content: '';*/
                /*   display: inline-block;*/
                /*   width: 2vw;*/
                /*   height: 2vw;*/
                /*   border: 1px solid #fff;*/
                /*   border-radius: 50%;*/
                /*   position: absolute;*/
                /*   right: 50%;*/
                /*   top: 50%;*/
                /*   margin-right: -1vw;*/
                /*   margin-top: -1.1vw;*/
                /*}*/
            }
        }
        .empty {
            background: #fff;
            color: #333;
            height: 30vw;
            line-height: 30vw;
            text-align: center;
            font-size: 2vw;
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }


    @media screen and (max-width: 750px) {

        #content {
            width: 100%;
            .title{
                margin-bottom: 8%;
                h2{
                    .fontFn(23);
                    &+a{
                        .fontFn(13);
                        &::before{
                            width:6px;
                            height: 6px;
                            right: 12%;
                            top:20px;
                        }
                        &::after{
                            .fontFn(15,width);
                            .fontFn(15,height);
                        }
                    }
                }
                    
            }
            .information{
                display: block;
                padding: 0 2.6%;
                &>div{
                    width: 100%;
                    padding-bottom: 6%;
                    .list{
                        .cover{
                            .fontFn(5,border-radius);
                            overflow: hidden;
                            .link{
                                display: block;
                                .img{
                                    width: 100%;height: auto;
                                    img{width: 100%;height: auto}
                                }
                                span{
                                    .fontFn(15);
                                    padding: 2.8%;
                                    font-weight: normal;
                                }
                            }
                        }
                        ul{
                            height: auto;
                            margin-top: 4%;
                            li{
                                .fontFn(15);
                                .fontFn(44,line-height);
                            }
                        }
                    }
                }
            }
            .pic-area{
                padding: 0 2.6%;
                margin-bottom: 6%;
                .area{
                    height: auto;
                    -moz-column-count:auto;
                    -webkit-column-count:auto;
                    column-count:auto;
                    &>div{
                        height: auto !important;
                        img{
                            height: auto !important;
                            margin-bottom:6%;
                        }
                    }
                }
            }
            .video-area{
                padding: 0 2.6%;
                margin-bottom: 6%;
                ul{
                    justify-content: center;
                    li{
                        margin-top: 6%;
                        margin-bottom: 0;
                        width: 45.3%;
                        p{
                            .fontFn(12);
                            padding:4% 3%;
                        }
                        i{
                            top:62%;
                            left: 4%;
                            .fontFn(14,width);
                            .fontFn(14,height);
                            border-width: 1px;
                            &::after{
                                top:20%;
                                left: 34%;
                                .fontFn(3,border-width);
                                .fontFn(5,border-left-width);
                            }
                        }
                    }
                }
            }
        }
    }
</style>